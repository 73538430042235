import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadScript from 'load-script';
import loadCss from 'loadcss';
import withBackendInterface from './BackendInterface';
import tracker from './tracker-interface';
import './GameContainer.css';
import { ProgressBarOverlay } from './ProgressBarOverlay';
import { quitGame } from './GlobalFunctions';

const basePath = window.location.pathname.includes('/play') ? '/play/' : '/';

class GameContainer extends Component {
  byPassGameLoad = false;

  constructor(props) {
    super(props);

    this.state = {
      isScriptsLoaded: false,
      gameInstance: undefined,
      webGLBuildVersion: '',
      contentDimensions: {
        width: '',
        height: '',
      },
      canvasDimensions: {
        width: '100%',
        height: '100%',
      },
      progress: 0,
    };
  }

  componentDidMount() {
    const buildInformationFile =
      import.meta.env.VITE_UNITY_BUILD_INFORMATION_FILE ||
      'UnityBuildInformation.json';
    fetch(`${basePath}TemplateData/${buildInformationFile}`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.setState({
          webGLBuildVersion: json.Version,
        });

        const config = buildLoaderConfigObject(json);
        this.loadUnityScripts(json.loaderUrl, config);
      });

    window.addEventListener('resize', this.resizeContainer);
    this.resizeContainer();

    function buildLoaderConfigObject(json) {
      var buildUrl = `${basePath}Build`;
      var config = {
        dataUrl: `${buildUrl}/${json.dataUrl}`,
        frameworkUrl: `${buildUrl}/${json.frameworkUrl}`,
        codeUrl: `${buildUrl}/${json.codeUrl}`,
        streamingAssetsUrl: `${basePath}StreamingAssets`,
        companyName: `${json.companyName}`,
        productName: `${json.productName}`,
        productVersion: `${json.productVersion}`,
      };

      if (json.memoryUrl) {
        config.memoryUrl = `${buildUrl}/${json.memoryurl}`;
      } else if (json.symbolsUrl) {
        config.symbolsUrl = `${buildUrl}/${json.symbolsUrl}`;
      }
      return config;
    }
  }

  componentWillReceiveProps(nextProps) {
    this.updateWindowDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeContainer);

    /* global peekavilleWebContainer */
    quitGame();
  }

  onFullScreen = () => {
    if (this.state.gameInstance) {
      this.state.gameInstance.SetFullscreen(1);
    }
  };

  updateWindowDimensions = () => {
    if (this.myRef && this.myRef.clientWidth) {
      this.windowHeight = this.myRef.clientHeight;
      this.windowWidth = this.myRef.clientWidth;
    } else {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    }
  };

  updateContainerDimensions = (width, height) => {
    // magic number 4epx is  is hardcoded in the
    // style.css that is provided by Unity for the
    // height of the web-content footer
    this.setState({
      canvasDimensions: {
        width: `${width}px`,
        height: `${height - 70}px`,
      },
      contentDimensions: {
        width: `${width}px`,
        height: `${height}px`,
      },
    });

    const canvas = document.getElementById('#canvas');
    if (canvas) {
      canvas.width = width;
      canvas.height = height - 70;
    }
  };

  resizeContainer = () => {
    this.updateWindowDimensions();

    let newContainerHeight;
    let newContainerWidth;

    if (this.shouldLimitHeight()) {
      newContainerHeight = this.windowHeight;
      newContainerWidth = Math.floor(this.windowHeight * 1.778);

      this.updateContainerDimensions(newContainerWidth, newContainerHeight);
    } else {
      newContainerWidth = this.windowWidth;
      newContainerHeight = Math.floor(newContainerWidth * 0.5625);

      this.updateContainerDimensions(newContainerWidth, newContainerHeight);
    }
  };

  shouldLimitHeight = () => this.windowWidth / this.windowHeight >= 1.778;

  executeUnity = async (loaderConfig) => {
    // UnityLoader and UnitProgress are created from
    // these scripts that are going to be dynamically loaded
    // eslint-disable-next-line no-undef
    if (this.byPassGameLoad) {
      return;
    }

    const gameInstance = await createUnityInstance(
      document.querySelector('#gameContainer'),
      loaderConfig,
      // eslint-disable-next-line no-undef
      (completionPercent) => {
        this.setState({
          progress: completionPercent,
        });
      },
    );

    peekavilleWebContainer.gameInstance = gameInstance;

    this.setState({
      gameInstance,
      isScriptsLoaded: true,
    });
  };

  loadUnityStylesheet = () =>
    new Promise((resolve) => {
      loadCss(`/TemplateData/style.css`, () => {
        resolve('Success');
      });
    });

  loadUnityLoaderScript = (loaderFilename) =>
    new Promise((resolve, reject) => {
      loadScript(`${basePath}Build/${loaderFilename}`, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve('Success');
        }
      });
    });

  loadUnityScripts = (loaderFilename, loaderConfig) => {
    Promise.all([
      this.loadUnityLoaderScript(loaderFilename),
      this.loadUnityStylesheet(),
    ]).then(
      () => {
        this.executeUnity(loaderConfig);
      },
      (failure) => {
        console.error(JSON.stringify(failure, null, 2));
      },
    );
  };

  render() {
    const progressBarStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
    return (
      <div
        className='gameContainer'
        ref={(input) => {
          this.myRef = input;
        }}
      >
        <div className='webgl-content' style={this.state.contentDimensions}>
          {!this.state.isScriptsLoaded && (
            <ProgressBarOverlay
              progress={this.state.progress}
              width='40em'
              style={progressBarStyle}
            />
          )}
          <canvas id='gameContainer' style={this.state.canvasDimensions} />
          <div className='footer'>
            <div
              id='unity-fullscreen-button'
              style={{ float: 'left' }}
              onClick={this.onFullScreen}
              tabIndex={0}
              role='button'
            />
            <div className='gameContainerTitle'>
              {this.props.tryoutProfile &&
                this.props.tryoutProfile.district !== 'UNKNOWN' && (
                  <span>
                    Licensed to:{' '}
                    {this.props.tryoutProfile.accountName ||
                      this.props.tryoutProfile.id}{' '}
                  </span>
                )}
              myPeekaville v{this.state.webGLBuildVersion}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GameContainer.propTypes = {
  onStoreGameState: PropTypes.func.isRequired,
  onRetrieveGameState: PropTypes.func.isRequired,
  onGetUserProfile: PropTypes.func.isRequired,
  onCreateJournalEntry: PropTypes.func.isRequired,
};

GameContainer.defaultProps = {
  isShow: true,
};

const mapStateToProps = (state) => ({
  tryoutProfile: state.entry.tryoutProfile,
});

export default tracker.withPageView(
  connect(mapStateToProps, null)(withBackendInterface(GameContainer)),
);
